import { CommentsDisabledOutlined } from '@mui/icons-material';
import { API, graphqlOperation } from 'aws-amplify';
import Cookies from 'js-cookie';
import { error } from 'logrocket';


function getDoctorValidation(payload) {
    const token = Cookies.get('tokenId');
    let params
    if (payload?.nextToken) {
        params = `doctorId : "${payload?.doctorId}", nextToken : "${payload?.nextToken}"`
    } else {
        params = `doctorId : "${payload?.doctorId}"`
    }

    return API.graphql({
        query: `query MyQuery {
            getDoctorValidation(${params}) {
                items {
                    DOB
                    assignType
                    createdAt
                    doctorName
                    gender
                    patientName
                    screeningDate
                    screeningId
                    eventId
                    status
                    schoolId
                    studentId
                    updatedAt
                    userId
                    validated
                    validatedOn
                    validationDueDate
                    validationId
                }
                nextToken
                }
            }`,
        authToken: token
    })
}

function assignDoctorValidation(payload) {
    const token = Cookies.get('tokenId');

    const params = payload?.payload
    return API.graphql({
        query: `mutation MyMutation {
            assignDoctorValidation(assignType: ${params?.screeningType}, doctorId: "${params?.doctorId}", dueDate: "${params?.dueDate}", limit: ${params?.limit})
          }`,
        authToken: token
    })
}

function getDoctorValidationProgress(payload) {
    const token = Cookies.get('tokenId');

    const params = payload?.payload
    return API.graphql({
        query: `query MyQuery {
            getDoctorValidationProgressInRange(fromDate:"${params.fromDate}") {
              assignCounts
              assignType
              doctorName
              validationCount
              email
              differentMap
            }
          }`,
        authToken: token
    })
}

function getDoctorValidationCount(payload) {
    const token = Cookies.get('tokenId');

    const params = payload?.payload
    return API.graphql({
        query: `query MyQuery {
            getDoctorValidationCount(identify: "${params}") {
              userId
              count
              validated
            }
          }`,
        authToken: token
    })
}

function getDoctorInterpreations(payload) {
    // 
    const token = Cookies.get('tokenId');
    return API.graphql({
        query: `query MyQuery {
                getScreening(eventId: "${payload?.eventId}", screeningId: "${payload?.screeningId}") {
                screeningId
                studentId
                createdAt
                updatedAt
                auditTrail
                physicalScreeningDate
                dentalScreeningDate
                status
                reExam
                vitalSignsAssessment {
                    temperature
                    temperatureInterpretation
                    hemoglobin
                    hemoglobinInterpretation
                    heartRate
                    heartRateInterpretation
                    bloodPressure
                    bloodPressureInterpretation
                    SpO2
                    SpO2Interpretation
                    BMI
                    BMIInterpretation
                    height
                    weight
                }
                skinAssessment {
                    validation {
                        issue
                        interpretation
                    }
                    validationInterpretation
                }
                hairAssessment {
                    color
                    colorInterpretation
                    dandruff
                    dandruffInterpretation
                    lossOfHair
                    lossOfHairInterpretation
                    pediculosis
                    pediculosisInterpretation
                }  
                eyeAssessment {
                    validation {
                        issue
                        interpretation
                    }
                    validationInterpretation
                }
                hearingAssessment {
                    validation {
                        issue
                        interpretation
                    }
                    validationInterpretation   
                }
                earAssessment {
                    leftEarInterpretation
                    leftEarValidation {
                        issue
                        interpretation
                    }
                    rightEarInterpretation
                    rightEarValidation {
                        issue
                        interpretation
                    }
                }
                dentalAssessment {  
                    DMFTIndex
                    DMFTIndexInterpretation
                    decoloration
                    decolorationInterpretation
                    gingivitis
                    gingivitisInterpretation
                    orthodontic
                    orthodonticInterpretation
                    fillingPresent
                    fillingPresentInterpretation
                    missedTooth
                    missedToothInterpretation
                    poorOralHygiene
                    poorOralHygieneInterpretation
                    cariesDecay
                            cariesDecayInterpretation
                    tooth
                }
                coughAssessment{
                    validation {
                        issue
                        interpretation
                    }
                    validationInterpretation
                }
                throatAssessment{
                    validation {
                        issue
                        interpretation
                    } 
                    validationInterpretation 
                }
                abdomenAssessment {
                    validation {
                        issue
                        interpretation
                    }
                    validationInterpretation
                }
                lungsAssessment {
                    lungReportUrl_1
                    lungReportUrl_2
                    auscultationValidation {
                        issue
                        interpretation
                    }
                    auscultationInterpretation
                }
                heartAssessment {
                    heartReportUrl
                    estimationValidation {
                        issue
                        interpretation
                    }
                    estimationInterpretation
                    auscultationValidation {
                        issue
                        interpretation
                    }
                    auscultationInterpretation
                }
            }
        }`,
        authToken: token
    })
}

function updateDoctorInterpretations(payload) {
    const {
        screeningId,
        eventId,
        vitalSignsAssessment,
        skinAssessment,
        hairAssessment,
        eyeAssessment,
        earAssessment,
        hearingAssessment,
        dentalAssessment,
        throatAssessment,
        coughAssessment,
        lungsAssessment,
        heartAssessment,
        abdomenAssessment,
        physicalScreeningDate,
        status
    } = payload;

    const interpretation = payload.interpretationsData
    const token = Cookies.get('tokenId');
    return API.graphql(
        graphqlOperation(`mutation MyMutation(
            $status : String,
            $skinObjects : [ValidationInput],
            $eyeObjects :  [ValidationInput],
            $leftEarObjects : [ValidationInput],
            $rightEarObjects : [ValidationInput],
            $hearingObjects:  [ValidationInput],
            $coughObjects : [ValidationInput],
            $throatObjects : [ValidationInput] ,
            $abdomenObjects:  [ValidationInput],
            $lungAuscultationObjects : [ValidationInput],
            $heartEstimationObjects :  [ValidationInput],
            $heartAuscultationObjects :  [ValidationInput],
            ${payload.physicalDone ? `$physicalScreeningDate : AWSDate` : ``}
        ){
                    updateScreening(        
                        ${payload.physicalDone ? `physicalScreeningDate : $physicalScreeningDate` : ``},
                        screeningId : "${screeningId}",
                        eventId : "${eventId}",
                        status : $status,
                        vitalSignsAssessment: {
                            temperature : "${vitalSignsAssessment?.temperature}"
                            temperatureInterpretation : "${vitalSignsAssessment?.temperatureInterpretation}"
                            hemoglobin : "${vitalSignsAssessment?.hemoglobin}"
                            hemoglobinInterpretation : "${vitalSignsAssessment?.hemoglobinInterpretation}"
                            heartRate : "${vitalSignsAssessment?.heartRate}"
                            heartRateInterpretation : "${vitalSignsAssessment?.heartRateInterpretation}"
                            bloodPressure : "${vitalSignsAssessment?.bloodPressure}"
                            bloodPressureInterpretation :  "${vitalSignsAssessment?.bloodPressureInterpretation}"
                            SpO2 : "${vitalSignsAssessment?.SpO2}"
                            SpO2Interpretation : "${vitalSignsAssessment?.SpO2Interpretation}"
                            BMI : "${vitalSignsAssessment?.BMI}"
                            BMIInterpretation : "${vitalSignsAssessment?.BMIInterpretation}"
                            height : "${vitalSignsAssessment?.height}"
                            weight : "${vitalSignsAssessment?.weight}"
                        },
                        skinAssessment: {
                            validation : $skinObjects
                            validationInterpretation : "${skinAssessment.validationInterpretation}"
                        },
                        hairAssessment: {
                            color : "${hairAssessment?.color}"
                            colorInterpretation : "${hairAssessment?.colorInterpretation}"
                            dandruff : ${hairAssessment?.dandruff}
                            dandruffInterpretation : "${hairAssessment?.dandruffInterpretation}"
                            lossOfHair : ${hairAssessment?.lossOfHair}
                            lossOfHairInterpretation : "${hairAssessment?.lossOfHairInterpretation}"
                            pediculosis : ${hairAssessment?.pediculosis}
                            pediculosisInterpretation : "${hairAssessment?.pediculosisInterpretation}"
                        },
                        eyeAssessment: {
                            validation : $eyeObjects
                            validationInterpretation : "${eyeAssessment?.validationInterpretation}"
                        },
                        earAssessment: {
                            leftEarInterpretation : "${earAssessment?.leftEarInterpretation}"
                            leftEarValidation :  $leftEarObjects
                            rightEarInterpretation : "${earAssessment?.rightEarInterpretation}"
                            rightEarValidation :  $rightEarObjects
                        },
                        hearingAssessment: {
                            validation: $hearingObjects,
                            validationInterpretation: "${hearingAssessment?.validationInterpretation}"   
                        }
                        dentalAssessment: {  
                            DMFTIndex : ${dentalAssessment?.DMFTIndex}
                            DMFTIndexInterpretation : "${dentalAssessment?.DMFTIndexInterpretation}"
                            decoloration: ${dentalAssessment?.decoloration}
                            decolorationInterpretation : "${dentalAssessment?.decolorationInterpretation}"
                            gingivitis : ${dentalAssessment?.gingivitis}
                            gingivitisInterpretation : "${dentalAssessment?.gingivitisInterpretation}"
                            orthodontic : ${dentalAssessment?.orthodontic}
                            orthodonticInterpretation : "${dentalAssessment?.orthodonticInterpretation}"
                            fillingPresent : ${dentalAssessment?.fillingPresent},
                            missedTooth : ${dentalAssessment?.missedTooth},
                            cariesDecay : ${dentalAssessment?.cariesDecay},
                            poorOralHygiene : ${dentalAssessment?.poorOralHygiene},
                            tooth : "${dentalAssessment?.tooth}"
                        },
                        coughAssessment: {
                            validation : $coughObjects,
                            validationInterpretation : "${coughAssessment?.validationInterpretation}"
                        },
                        throatAssessment: {
                            validation : $throatObjects,
                            validationInterpretation : "${throatAssessment?.validationInterpretation}"
                        },
                        abdomenAssessment: {
                            validation : $abdomenObjects
                            validationInterpretation : "${abdomenAssessment?.validationInterpretation}"
                        },
                        lungsAssessment: {
                            lungReportUrl_1: "${lungsAssessment?.lungReportUrl_1}",
                            lungReportUrl_2: "${lungsAssessment?.lungReportUrl_2}",
                            auscultationValidation :  $lungAuscultationObjects,
                            auscultationInterpretation : "${lungsAssessment?.auscultationInterpretation}"
                        },
                        heartAssessment: {
                            heartReportUrl: "${heartAssessment?.heartReportUrl}"
                            estimationValidation :  $heartEstimationObjects
                            estimationInterpretation : "${heartAssessment?.estimationInterpretation}"
                            auscultationValidation : $heartAuscultationObjects
                            auscultationInterpretation : "${heartAssessment?.auscultationInterpretation}"
                        }
                    ) {
                        screeningId
                        studentId
                        createdAt
                        updatedAt
                        status
                        auditTrail
                        physicalScreeningDate
                        dentalScreeningDate
                        vitalSignsAssessment {
                            temperature
                            temperatureInterpretation
                            hemoglobin
                            hemoglobinInterpretation
                            heartRate
                            heartRateInterpretation
                            bloodPressure
                            bloodPressureInterpretation
                            SpO2
                            SpO2Interpretation
                            BMI
                            BMIInterpretation
                            height
                            weight
                        }
                        skinAssessment {
                            validation{
                                issue
                                interpretation
                            }
                            validationInterpretation
                        }
                        hairAssessment {
                            color
                            colorInterpretation
                            dandruff
                            dandruffInterpretation
                            lossOfHair
                            lossOfHairInterpretation
                            pediculosis
                            pediculosisInterpretation
                        }  
                        eyeAssessment {
                            validation{
                                issue
                                interpretation
                            }
                            validationInterpretation
                        }
                        hearingAssessment {
                            validation {
                                issue
                                interpretation
                            }
                            validationInterpretation   
                        }
                        earAssessment {
                            leftEarInterpretation
                            leftEarValidation {
                                issue
                                interpretation
                            }
                            rightEarInterpretation
                            rightEarValidation{
                                issue
                                interpretation
                            }
                        }
                        dentalAssessment {  
                            DMFTIndex
                            DMFTIndexInterpretation
                            decoloration
                            decolorationInterpretation
                            gingivitis
                            gingivitisInterpretation
                            orthodontic
                            orthodonticInterpretation
                            fillingPresent
                            fillingPresentInterpretation
                            missedTooth
                            missedToothInterpretation
                            poorOralHygiene
                            poorOralHygieneInterpretation
                            cariesDecay
                            cariesDecayInterpretation
                            tooth
                        }
                        coughAssessment{
                            validation {
                                issue
                                interpretation
                            }
                            validationInterpretation
                        }
                        throatAssessment{
                            validation {
                                issue
                                interpretation
                            }
                            validationInterpretation 
                        }
                        abdomenAssessment {
                            validation {
                                issue
                                interpretation
                            }
                            validationInterpretation
                        }
                        lungsAssessment {
                            lungReportUrl_1
                            lungReportUrl_2
                            auscultationValidation {
                                issue
                                interpretation
                            }
                            auscultationInterpretation
                        }
                        heartAssessment {
                            heartReportUrl
                            estimationValidation {
                                issue
                                interpretation
                            }
                            estimationInterpretation
                            auscultationValidation {
                                issue
                                interpretation
                            }
                            auscultationInterpretation
                        }
            }
        }`,
            {
                status: status,
                skinObjects: skinAssessment?.validation,
                eyeObjects: eyeAssessment?.validation,
                leftEarObjects: earAssessment?.leftEarValidation,
                rightEarObjects: earAssessment?.rightEarValidation,
                hearingObjects: hearingAssessment?.validation,
                coughObjects: coughAssessment?.validation,
                throatObjects: throatAssessment?.validation,
                abdomenObjects: abdomenAssessment?.validation,
                lungAuscultationObjects: lungsAssessment?.auscultationValidation,
                heartEstimationObjects: heartAssessment?.estimationValidation,
                heartAuscultationObjects: heartAssessment?.auscultationValidation,
                physicalScreeningDate: physicalScreeningDate.toString()
            }, token
        )
    )
}

function updateValidation(payload) {

    const token = Cookies.get('tokenId');

    const params = payload?.payload
    return API.graphql({
        query: `mutation MyMutation($validatedOn: AWSDate = "${payload.awsDate}") {
            updateValidation(
                userId: "${payload.userId}", 
                validationId: "${payload.validationId}", 
                validated: true,
                validatedOn: $validatedOn
                status : "VALIDATION_DONE"
            ) {
                validationId
                userId
                screeningId
                eventId
                studentId
                patientName
                DOB
                gender
                screeningDate
                validationDueDate
                status
                validated
                assignType
                doctorName
                validatedOn
                createdAt
                updatedAt
            }
        }`,
        authToken: token
    })
}




function updateDentalInterpretation(payload, onSuccess, onFailure) {
    const {
        screeningId,
        eventId,
        dentalAssessment,
        status,
        awsDate,
        dentalDone,
        screeningStatus
    } = payload;
    const token = Cookies.get('tokenId');
    return API.graphql(
        graphqlOperation(
            `mutation MyMutation
                ${dentalDone ? `($dentalValidatedOn: AWSDate)` : ``}
                {
                    updateScreening(       
                        screeningId : "${screeningId}",
                        eventId : "${eventId}",
                        status : "${status}",
                        ${dentalDone ? `dentalScreeningDate : $dentalValidatedOn` : ``}
                        dentalAssessment: {  
                            DMFTIndex : 10,
                            DMFTIndexInterpretation : "${dentalAssessment?.DMFTIndexInterpretation}",
                            decoloration: ${dentalAssessment?.decoloration},
                            decolorationInterpretation : "${dentalAssessment?.decolorationInterpretation}"
                            gingivitis : ${dentalAssessment?.gingivitis},
                            gingivitisInterpretation : "${dentalAssessment?.gingivitisInterpretation}",
                            orthodontic : ${dentalAssessment?.orthodontic},
                            orthodonticInterpretation : "${dentalAssessment?.orthodonticInterpretation}",
                            fillingPresent : ${dentalAssessment?.fillingPresent},
                            missedTooth : ${dentalAssessment?.missedTooth},
                            cariesDecay : ${dentalAssessment?.cariesDecay},
                            poorOralHygiene : ${dentalAssessment?.poorOralHygiene}
                            tooth : "${dentalAssessment?.tooth}",
                        },
                        screeningStatus: {
                            cough: {exam: ${screeningStatus?.cough?.exam}}, 
                            abdomin: {auscultation: ${screeningStatus?.abdomin.auscultation}}, 
                            dental: {
                                decoloration: ${screeningStatus?.dental?.decoloration},
                                gingivitis: ${screeningStatus?.dental?.gingivitis},
                                orthodontic: ${screeningStatus?.dental?.orthodontic},
                                tooth: ${screeningStatus?.dental?.tooth},
                                resultAwait: ${screeningStatus?.dental?.resultAwait}
                            },
                            ear: {
                                rightEarVideo: ${screeningStatus?.ear?.rightEarVideo}, 
                                leftEarVideo: ${screeningStatus?.ear?.leftEarVideo}
                            }, 
                            eye: {normalVision: ${screeningStatus?.eye?.normalVision}},
                            hair: {
                                color: ${screeningStatus?.hair?.color}, 
                                lossOfHair: ${screeningStatus?.hair?.lossOfHair}, 
                                pediculosis: ${screeningStatus?.hair?.pediculosis}, 
                                dandruff: ${screeningStatus?.hair?.dandruff}
                            }, 
                            hearing: {
                                hearingAssessment: ${screeningStatus?.hearing?.hearingAssessment},
                                resultAwait  : ${screeningStatus?.hearing?.resultAwait}
                            },
                            heart: {estimation: ${screeningStatus?.heart?.estimation}}, 
                            lungs: {auscultation: ${screeningStatus?.lungs?.auscultation}}, 
                            skin: {video: ${screeningStatus?.skin?.video}}, 
                            throat: {video: ${screeningStatus?.throat?.video}}, 
                            vitalSigns: {
                                SpO2: ${screeningStatus?.vitalSigns?.SpO2}, 
                                bloodPressure: ${screeningStatus?.vitalSigns?.bloodPressure}, 
                                heartRate: ${screeningStatus?.vitalSigns?.heartRate}, 
                                height: ${screeningStatus?.vitalSigns?.height}, 
                                hemoglobin: ${screeningStatus?.vitalSigns?.hemoglobin}, 
                                weight: ${screeningStatus?.vitalSigns?.weight}, 
                                temperature: ${screeningStatus?.vitalSigns?.temperature}
                                heightAwait: ${screeningStatus?.vitalSigns?.heightAwait},
                                weightAwait: ${screeningStatus?.vitalSigns?.weightAwait}
                            }                          
                        }    
                    ) {
                        screeningId
                        eventId
                        studentId
                        createdAt
                        updatedAt
                        status
                        dentalScreeningDate  
                        physicalScreeningDate
                        screeningPackageDetails {
                            vitalSigns
                            throat
                            skin
                            packageType
                            lungs
                            learningScreening
                            heart
                            hair
                            eye
                            ear
                            dental
                            behavioralScreening
                            abdomen
                        }  
                        dentalAssessment {  
                            DMFTIndex
                            DMFTIndexInterpretation
                            decoloration
                            decolorationInterpretation
                            gingivitis
                            gingivitisInterpretation
                            orthodontic
                            orthodonticInterpretation
                            fillingPresent
                            fillingPresentInterpretation
                            missedTooth
                            missedToothInterpretation
                            poorOralHygiene
                            poorOralHygieneInterpretation
                            cariesDecay
                            cariesDecayInterpretation
                            tooth
                        }
                        screeningStatus {
                            abdomin {
                              auscultation
                            }
                            cough {
                              exam
                            }
                            dental {
                              decoloration
                              gingivitis
                              orthodontic
                              tooth
                              resultAwait
                            }
                            ear {
                              leftEarVideo
                              rightEarVideo
                            }
                            hearing{
                              hearingAssessment
                              resultAwait
                            }
                            eye {
                              normalVision
                            }
                            hair  {
                              color
                              dandruff
                              lossOfHair
                              pediculosis
                            }
                            lungs {
                              auscultation
                            }
                            heart {
                              estimation
                            }
                            skin {
                              video
                            }
                            throat {
                              video
                            }
                            vitalSigns {
                              SpO2
                              bloodPressure
                              heartRate
                              height
                              heightAwait
                              hemoglobin
                              temperature
                              weight
                              weightAwait
                            }
                          }
                    }   
                }`,
            {
                dentalValidatedOn: awsDate
            }
            , token)
    ).then((data) => {
        onSuccess(data.data.updateScreening);
    }).catch((error) => {
        onFailure(error);
    })
}



function subscribeDentalScreening(payload) {
    return `subscription MySubscription {
    subscribeToUpdateScreening(eventId: "${payload.eventId}", screeningId: "${payload.screeningId}") {
        screeningId
        eventId
        studentId
        createdAt
        updatedAt
        status
        physicalScreeningDate
        dentalScreeningDate
        screeningPackageDetails {
            vitalSigns
            throat
            skin
            packageType
            lungs
            learningScreening
            heart
            hair
            eye
            ear
            dental
            behavioralScreening
            abdomen
        }
        dentalAssessment {  
            DMFTIndex
            DMFTIndexInterpretation
            decoloration
            decolorationInterpretation
            gingivitis
            gingivitisInterpretation
            orthodontic
            orthodonticInterpretation
            fillingPresent
            fillingPresentInterpretation
            missedTooth
            missedToothInterpretation
            poorOralHygiene
            poorOralHygieneInterpretation
            cariesDecay
            cariesDecayInterpretation
            tooth
        }
        screeningStatus {
            abdomin {
              auscultation
            }
            cough {
              exam
            }
            dental {
              decoloration
              gingivitis
              orthodontic
              tooth
              resultAwait
            }
            ear {
              leftEarVideo
              rightEarVideo
            }
            hearing{
              hearingAssessment
              resultAwait
            }
            eye {
              normalVision
            }
            hair  {
              color
              dandruff
              lossOfHair
              pediculosis
            }
            lungs {
              auscultation
            }
            heart {
              estimation
            }
            skin {
              video
            }
            throat {
              video
            }
            vitalSigns {
              SpO2
              bloodPressure
              heartRate
              height
              heightAwait
              hemoglobin
              temperature
              weight
              weightAwait
            }
        }
    }
} `;
}


function getDentalInterpretation(payload, onSuccess, onFailure) {
    const token = Cookies.get('tokenId');
    return API.graphql({
        query: `query MyQuery {
                getScreening(eventId: "${payload?.eventId}", screeningId: "${payload?.screeningId}") {
                screeningId
                studentId
                createdAt
                updatedAt
                status
                physicalScreeningDate
                dentalScreeningDate
                screeningPackageDetails {
                    vitalSigns
                    throat
                    skin
                    packageType
                    lungs
                    learningScreening
                    heart
                    hair
                    eye
                    ear
                    dental
                    behavioralScreening
                    abdomen
                }
                dentalAssessment {  
                    DMFTIndex
                    DMFTIndexInterpretation
                    decoloration
                    decolorationInterpretation
                    gingivitis
                    gingivitisInterpretation
                    orthodontic
                    orthodonticInterpretation
                    fillingPresent
                    fillingPresentInterpretation
                    missedTooth
                    missedToothInterpretation
                    poorOralHygiene
                    poorOralHygieneInterpretation
                    cariesDecay
                    cariesDecayInterpretation
                    tooth
                }
                screeningStatus {
                    abdomin {
                        auscultation
                    }
                    cough {
                        exam
                    }
                    dental {
                        decoloration
                        gingivitis
                        orthodontic
                        tooth
                        resultAwait
                    }
                    ear {
                        leftEarVideo
                        rightEarVideo
                    }
                    hearing{
                        hearingAssessment
                        resultAwait
                    }
                    eye {
                        normalVision
                    }
                    hair  {
                        color
                        dandruff
                        lossOfHair
                        pediculosis
                    }
                    lungs {
                        auscultation
                    }
                    heart {
                        estimation
                    }
                    skin {
                        video
                    }
                    throat {
                        video
                    }
                    vitalSigns {
                        SpO2
                        bloodPressure
                        heartRate
                        height
                        heightAwait
                        hemoglobin
                        temperature
                        weight
                        weightAwait
                    }
                }
            }
        }`,
        authToken: token
    }).then((data) => {
        onSuccess(data?.data?.getScreening);
    }).catch((error) => {
        onFailure(error);
    })
}


function getDentalValidation(callBack) {
    const token = Cookies.get('tokenId');
    return API.graphql(graphqlOperation(
        `query MyQuery {
            getDentalValidation
          }`,
        {

        }
        ,
        token
    )).then((data) => {
        callBack('success', JSON.parse(data?.data?.getDentalValidation));
    }).catch((error) => {
        callBack('failure', error);
    })
}


function createReExam(payload, callBack) {
    const token = Cookies.get('tokenId');
    return API.graphql(graphqlOperation(
        `mutation MyMutation(
            $assignType : String!,
            $doctorId : String!,
            $doctorName : String!,
            $organ : [InputOrganReason]!,
            $screeningId : String!,
            $userId : String! ,
            $validationId  : String!
        ){
            createReExam(
                assignType: $assignType, 
                doctorId: $doctorId, 
                doctorName: $doctorName, 
                organ: $organ, 
                screeningId: $screeningId, 
                userId: $userId, 
                validationId: $validationId,)
                {
                    organ {
                        organName
                        reason
                      }
                }
        }`,
        {
            assignType: payload?.assignType,
            doctorId: payload?.doctorId,
            doctorName: payload?.doctorName,
            organ: payload?.organ,
            screeningId: payload?.screeningId,
            userId: payload?.userId,
            validationId: payload?.validationId,
        },
        token
    )).then((data) => {
        callBack('success', data);
        // console.log(data);
    }).catch((error) => {
        console.log(error);
        callBack('failure', error);
    })
}


function completeReExam(payload) {
    const token = Cookies.get('tokenId');
    return API.graphql({
        query: ` mutation MyMutation {
            completeReExam(screeningId: "${payload.screeningId}", userId: "${payload.userId}"){
                organ {
                    organName
                    reason
                }
            }
        }`,
        authToken: token
    }).then((response) => {
        console.log(response);
    }).catch((erro) => {
        console.log(error);
    })




}

const getAIValidation = (payload) => {
    const token = Cookies.get('tokenId');
    return API.graphql({
        query: `query MyQuery {
                getAIValidation(screeningId: "${payload?.screeningId}", parameter: "${payload?.parameter}") {
                  statusCode
                  data
                  message
                }
              }`,
        authToken: token
    })
}



const validation = {
    getDoctorValidation,
    getDoctorInterpreations,
    updateDoctorInterpretations,
    assignDoctorValidation,
    getDoctorValidationCount,
    getDoctorValidationProgress,
    updateValidation,
    updateDentalInterpretation,
    getDentalInterpretation,
    getDentalValidation,
    subscribeDentalScreening,
    createReExam,
    completeReExam,
    getAIValidation
}

export default validation